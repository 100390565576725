import {
    ArrowForwardIcon,
    Box,
    enerbitColors,
    Grid,
    IconButton,
    styled,
    Typography,
} from "@enerbit/base";
import SectionContainer from "../../../components/container/section-container";
import ChargingStationIcon from "@mui/icons-material/ChargingStation";
import StatusTag from "../../../components/container/status-tag";
import { MeterHistoryData } from "../../../models/meters";
import { generateRandomId } from "../../../utils/common";
import { navigateToUrl } from "single-spa";

interface Props {
    meter: MeterHistoryData;
}

const meterDataKeys: { label: string; key: keyof MeterHistoryData }[] = [
    {
        label: "Serial de medidor",
        key: "serial",
    },
    { label: "Modelo de medidor", key: "model" },
    { label: "Desde", key: "since" },
    { label: "Hasta", key: "until" },
];

const MeterItem = ({ meter }: Props) => {
    const goToMeterDetail = (id: string) => {
        navigateToUrl(`/#/meters/${id}`);
    };
    return (
        <SectionContainer
            sx={{
                borderRadius: "4px",
                borderColor: !meter.active
                    ? enerbitColors.error.main
                    : enerbitColors.primary[200],
            }}
        >
            <Box sx={{ display: "flex", gap: 3, alignItems: "center" }}>
                <IconContainer bgcolor={"#8B76D6"}>
                    <ChargingStationIcon sx={{ color: "#FFF" }} />
                </IconContainer>
                <Grid container columnSpacing={2}>
                    {meterDataKeys.map((value) => (
                        <Grid item xs={2.6} key={generateRandomId()}>
                            <Typography
                                sx={{
                                    color: enerbitColors.neutral.main,
                                    fontSize: "12px",
                                }}
                            >
                                {value.label}
                            </Typography>
                            <Typography
                                sx={{
                                    fontWeight: 700,
                                    color: enerbitColors.neutral[900],
                                }}
                            >
                                {meter[value.key]}
                            </Typography>
                        </Grid>
                    ))}
                    <Grid
                        item
                        xs={1.6}
                        alignItems={"flex-end"}
                        alignContent={"center"}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                gap: 2,
                                alignItems: "center",
                            }}
                        >
                            {meter.active ? (
                                <StatusTag
                                    bgcolor={enerbitColors.success[100]}
                                    fontcolor={enerbitColors.success.main}
                                >
                                    Activo
                                </StatusTag>
                            ) : (
                                <StatusTag
                                    bgcolor={enerbitColors.error[100]}
                                    fontcolor={enerbitColors.error.main}
                                >
                                    Inactivo
                                </StatusTag>
                            )}
                        </Box>
                    </Grid>
                </Grid>
                <IconButton
                    onClick={() => {
                        goToMeterDetail(meter.id);
                    }}
                    sx={{
                        backgroundColor: "#FF7705",
                        borderRadius: "12px",
                        height: "32px",
                        width: "32px",
                        ":hover": {
                            backgroundColor: "#FF7705",
                        },
                    }}
                >
                    <ArrowForwardIcon sx={{ color: "white" }} />
                </IconButton>
            </Box>
        </SectionContainer>
    );
};

export default MeterItem;

interface IconContainerProps {
    bgcolor: string;
}

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
const IconContainer: any = styled(Box)<IconContainerProps>(({ bgcolor }) => ({
    backgroundColor: bgcolor,
    height: "40px",
    width: "40px",
    padding: "4px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "6px",
}));
