import { Box, CustomAlert, Pagination, Skeleton, Stack } from "@enerbit/base";
import StyledModal from "../../../components/container/styled-modal";
import { generateRandomId } from "../../../utils";
import { useEffect, useState } from "react";
import { MeterHistoryData } from "../../../models";
import { getMeter, getMeterRelationships } from "../../../services";
import moment from "moment";
import { DATE_TIME_FORMAT } from "../../../common";
import MeterItem from "./meter-item";
import PaginationContainer from "../../../components/container/pagination-container";

interface Props {
    open: boolean;
    handleClose: () => void;
    id: string;
}

const MetersHistoryModal = ({ open, handleClose, id }: Props) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [meters, setMeters] = useState<MeterHistoryData[]>([]);

    const fetchMetersHistory = async (id: string, page: number) => {
        setError(false);
        setLoading(true);
        try {
            const relationships = await getMeterRelationships(id, page);
            const tempMeters = relationships.items.map(async (rel) => {
                const meterDetail = await getMeter(rel.meter_id);
                const data: MeterHistoryData = {
                    serial: meterDetail.serial,
                    model: meterDetail.meter_model.name,
                    since: moment(rel.started_at).format(DATE_TIME_FORMAT),
                    until: rel.ended_at
                        ? moment(rel.ended_at).format(DATE_TIME_FORMAT)
                        : "Actualidad",
                    active: !rel.ended_at,
                    id: meterDetail.id,
                };
                return data;
            });

            const formattedMeters = await Promise.all(tempMeters);

            setCurrentPage(relationships.page);
            setTotalPages(relationships.pages);
            setMeters(formattedMeters);
        } catch (_error) {
            setError(true);
        } finally {
            setLoading(false);
        }
    };

    const handlePagination = (
        event: React.ChangeEvent<unknown>,
        value: number,
    ) => {
        event.preventDefault();
        setCurrentPage(value - 1);
        fetchMetersHistory(id, value - 1);
    };

    useEffect(() => {
        if (!open) return;

        fetchMetersHistory(id, 0);
    }, [id, open]);
    return (
        <StyledModal
            open={open}
            onClose={handleClose}
            title="Historial de medidores"
        >
            {loading && (
                <>
                    {[...Array(7)].map(() => (
                        <Skeleton
                            variant="rectangular"
                            key={generateRandomId()}
                            height={56}
                            sx={{ mb: 2 }}
                        />
                    ))}
                </>
            )}
            {!loading && error && (
                <CustomAlert
                    severity="error"
                    text={
                        "Error cargando información de los puntos de medida hijo."
                    }
                    onClose={() => {}}
                />
            )}
            {!loading && !error && (
                <>
                    <Box
                        sx={{ maxHeight: "335px", overflowY: "scroll", pr: 1 }}
                    >
                        {meters
                            .sort((a, b) => Number(b.active) - Number(a.active))
                            .map((meter) => (
                                <Box key={meter.serial} sx={{ mb: 1 }}>
                                    <MeterItem meter={meter} />
                                </Box>
                            ))}
                    </Box>
                    <PaginationContainer sx={{ mt: 2 }}>
                        <Stack spacing={2}>
                            <Pagination
                                page={currentPage + 1}
                                count={totalPages}
                                shape="rounded"
                                onChange={handlePagination}
                            />
                        </Stack>
                    </PaginationContainer>
                </>
            )}
        </StyledModal>
    );
};

export default MetersHistoryModal;
