import moment from "moment";
import { Usages, Usagestatus } from "../models/usages";

export const formatUsages = (schedules: Usages[]) => {
    const groupedByDate = groupUsagesByDate(schedules);
    const results = getHourStatus(groupedByDate);
    return results;
};

const groupUsagesByDate = (data: Usages[]) => {
    return data.reduce(
        (acc, measurement) => {
            const date = moment(measurement.time_start).format("YYYY-MM-DD");
            if (!acc[date]) {
                acc[date] = [];
            }
            acc[date].push(measurement);
            return acc;
        },
        {} as Record<string, Usages[]>,
    );
};

const getHourStatus = (usages: Record<string, Usages[]>) => {
    const currentTime = moment();

    for (const [date, measurements] of Object.entries(usages)) {
        const hoursFound: Set<string> = new Set();

        measurements.forEach((measurement) => {
            const startTime = moment(measurement.time_start);
            const hourLabel = startTime.format("HH:mm");

            const isMeasurementEmpty =
                measurement.active_energy_imported === null;

            if (isMeasurementEmpty && startTime.isSame(currentTime, 'hour')) {
                measurement.status = Usagestatus.FUTURE;
            } else if (isMeasurementEmpty && !startTime.isAfter(currentTime)) {
                measurement.status = Usagestatus.MISSING;
            } else if (startTime.isAfter(currentTime)) {
                measurement.status = Usagestatus.FUTURE;
            } else if (measurement.is_estimated) {
                measurement.status = Usagestatus.ESTIMATED;
            } else {
                measurement.status = Usagestatus.EXISTS;
            }

            if (!isMeasurementEmpty) {
                hoursFound.add(hourLabel);
            }
        });

        const allHours = Array.from(
            { length: 24 },
            (_, i) => `${i.toString().padStart(2, "0")}:00`,
        );

        allHours.forEach((hour) => {
            if (!hoursFound.has(hour)) {
                const missingTime = `${date}T${hour}:00-05:00`;
                const isFuture = moment(missingTime).isSameOrAfter(currentTime, 'hour');

                const missingMeasurement: Usages = {
                    active_energy_imported: 0,
                    reactive_energy_imported: 0,
                    active_energy_exported: 0,
                    reactive_energy_exported: 0,
                    measurement_point_id: "",
                    time_start: missingTime,
                    time_end: "",
                    is_estimated: false,
                    status: isFuture ? Usagestatus.FUTURE : Usagestatus.MISSING,
                };

                usages[date].push(missingMeasurement);
            }
        });

        usages[date].sort((a, b) =>
            moment(a.time_start).diff(moment(b.time_start)),
        );

        usages[date] = usages[date].filter(
            (usg) => usg.active_energy_imported !== null,
        );
    }

    return usages;
};
